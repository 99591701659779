import React, { ChangeEvent, useState } from 'react';
import { format } from 'date-fns';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Collapse,
  FormControlLabel,
  Typography,
  useTheme,
} from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { formatAmount } from '../../../utils/common';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { IOpenOrder, OrderOrInvoiceTypes } from '../../../redux/overview/OverviewModels';
import InvoiceOrOrderFooter from './InvoiceOrOrderFooter';
import InvoiceOrOrderDetailsRow from './InvoiceOrOrderDetailsRow';

interface OrderDetails {
  order: IOpenOrder;
  selectable?: boolean;
  isSelected?: boolean;
  onSelect?: (invoice: IOpenOrder, checked: boolean) => void;
}

const OrderDetails = ({ order, isSelected, selectable, onSelect }: OrderDetails) => {
  const theme = useTheme();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const [collapseStatus, setCollapseStatus] = useState(false);

  const toggleStatus = () => {
    setCollapseStatus(!collapseStatus);
  };

  const handleSelection = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (onSelect) {
      onSelect(order, checked);
    }
  };

  return (
    <Box>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          {selectable ? (
            <FormControlLabel
              control={
                <Checkbox
                  disabled={order.inProgress}
                  checked={isSelected}
                  onChange={handleSelection}
                />
              }
              label={
                <Box display='flex' alignItems='center'>
                  <Typography variant='h6'>#{order.orderNumber}</Typography>
                </Box>
              }
            />
          ) : (
            <Typography variant='h6'>#{order.orderNumber}</Typography>
          )}
        </Box>
        <Box display='flex' flexDirection='column' alignItems='end'>
          {order.inProgress ? (
            <Chip sx={{ ml: 4 }} label='In Progress' color='primary' />
          ) : (
            <>
              <Typography variant='h6'>{formatAmount(order.orderAmount)}</Typography>
              <Typography variant='paragraph2' fontSize={14}>
                due {format(new Date(order.dueDate), 'MMM dd, yyyy')}
              </Typography>
              {order?.paymentDate && (
                <Typography variant='paragraph2' fontSize={14}>
                  paid {format(new Date(order.paymentDate), 'MMM dd, yyyy')}
                </Typography>
              )}
            </>
          )}
        </Box>
      </Box>
      <Collapse in={collapseStatus} unmountOnExit={true}>
        <Box
          sx={{
            border: `${theme.spacing(0.5)} solid ${grey[200]}`,
            background: grey[50],
            px: theme.spacing(8),
            mt: theme.spacing(8),
            width: '100%',
          }}
        >
          <InvoiceOrOrderDetailsRow
            label='Order Date'
            value={format(new Date(order?.orderDate || ''), 'MM/dd/yyyy')}
          />
          <InvoiceOrOrderDetailsRow label='Order Number' value={order.orderNumber} />
          <InvoiceOrOrderDetailsRow label='Estimate Number' value={order.estimateNumber} />
          <InvoiceOrOrderDetailsRow label='Account Executive' value={order.accountExecutive} />
          <InvoiceOrOrderDetailsRow label='Order Amount' value={formatAmount(order.orderAmount)} />
          {order.taxAmount > 0 && (
            <InvoiceOrOrderDetailsRow label='Tax Amount' value={formatAmount(order.taxAmount)} />
          )}
          <InvoiceOrOrderFooter type={OrderOrInvoiceTypes.Order} number={order?.orderNumber} />
        </Box>
      </Collapse>
      <Box display='flex' flexDirection='column' alignItems='end'>
        <Button
          sx={{
            mt: theme.spacing(8),
            ml: 'auto',
            padding: 0,
          }}
          onClick={toggleStatus}
        >
          {collapseStatus ? <RemoveIcon /> : <AddIcon />}
          <Typography fontWeight={500} fontSize='0.875rem' ml={theme.spacing(2)}>
            {collapseStatus ? 'Hide Details' : 'Show Details'}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default OrderDetails;
