import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import ErrorPage from '../../../components/ErrorPage/ErrorPage';
import { RootState, StoreDispatch } from '../../../redux/Store';
import { fetchTransactions, updateFilters } from '../../../redux/transaction/TransactionSlice';

import SpectrumPagination from '../../../components/Pagination/SpectrumPagination';
import SpectrumSearch from '../../../components/Spectrum/Search/SpectrumSearch';
import PeriodFilters from './PeriodFilters';
import {
  IOpenInvoice,
  IOpenOrder,
  OrderOrInvoiceTypes,
} from '../../../redux/overview/OverviewModels';
import InvoiceDetails from '../../Overview/Invoices/InvoiceDetails';
import OrderDetails from '../../Overview/Invoices/OrderDetails';
import { transformToInvoice, transformToOrder } from '../InvoiceAndOrderUtils';
import { ComponentLoader } from '../../../components/Loaders/Loader';

const InvoicesAndOrdersList = () => {
  const dispatch: StoreDispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const { filters, transaction, transactionHistoryStatus, error } = useSelector(
    (state: RootState) => state.transactionData,
  );
  const [searchText, setSearchText] = useState('');

  const invoicesAndOrders = transaction.data || [];

  useEffect(() => {
    dispatch(fetchTransactions(filters));
  }, [filters]);

  const handleChangeItemsPerPage = (items: number) => {
    dispatch(updateFilters({ ...filters, start: 0, length: items }));
  };
  const handlePageChange = (pageNo: number) => {
    dispatch(updateFilters({ ...filters, start: pageNo * filters.length }));
  };
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchText(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      dispatch(updateFilters({ ...filters, start: 0, searchValue: searchText }));
    }
  };

  const renderTransactionHistory = () => {
    if (transactionHistoryStatus === 'loading') {
      return (
        <Box sx={{ mt: 35, mb: 35 }}>
          <ComponentLoader />
        </Box>
      );
    }

    if (transactionHistoryStatus === 'failed') {
      return <ErrorPage title='Something went wrong' message={error} />;
    }

    if (!invoicesAndOrders?.length) {
      return (
        <Typography color='info' my={theme.spacing(15)} variant='body2' textAlign='center'>
          You don&apos;t have any paid invoices & orders
        </Typography>
      );
    }
    return (
      <Box>
        {(invoicesAndOrders || []).map((invoicesAndOrder, index: number) => {
          if (invoicesAndOrder?.type === OrderOrInvoiceTypes.Invoice) {
            const invoiceDetails: IOpenInvoice = transformToInvoice(invoicesAndOrder);
            return (
              <Box
                key={index}
                my={6}
                border={`${theme.spacing(0.5)} solid ${theme.palette.grey[400]}`}
                padding={theme.spacing(5)}
              >
                <InvoiceDetails key={index} invoice={invoiceDetails} />
              </Box>
            );
          } else {
            const orderDetails: IOpenOrder = transformToOrder(invoicesAndOrder);
            return (
              <Box
                key={index}
                my={6}
                border={`${theme.spacing(0.5)} solid ${theme.palette.grey[400]}`}
                padding={theme.spacing(5)}
              >
                <OrderDetails key={index} order={orderDetails} />
              </Box>
            );
          }
        })}
      </Box>
    );
  };

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: isMobile ? 'column' : 'row',
          mb: 10,
          gap: 10,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexBasis: '100%',
            gap: theme.spacing(5),
          }}
        >
          <Typography variant='body1' fontWeight={700}>
            Showing results from:
          </Typography>
          <PeriodFilters filters={filters} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: theme.spacing(10),
            alignItems: 'center',
            justifyContent: 'flex-end',
            flexBasis: '100%',
          }}
        >
          <SpectrumSearch
            placeholder='Search...'
            size='small'
            name='filterValue'
            onChange={handleSearch}
            onKeyDown={handleKeyDown}
            fullWidth={isMobile}
          />
        </Box>
      </Box>
      <Box display='flex' flexDirection='column' rowGap={theme.spacing(4)}>
        {renderTransactionHistory()}
      </Box>
      {invoicesAndOrders?.length > 0 && transactionHistoryStatus !== 'loading' && (
        <table style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <tfoot>
            <tr>
              <SpectrumPagination
                onPageChange={handlePageChange}
                onItemsPerPageChange={handleChangeItemsPerPage}
                itemsPerPage={filters.length}
                currentPage={filters.start / filters.length}
                totalItems={transaction?.totalRecords || 0}
              />
            </tr>
          </tfoot>
        </table>
      )}
    </div>
  );
};

export default InvoicesAndOrdersList;
