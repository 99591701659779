import React from 'react';
import {
  IAdvertiserOrAgency,
  InvoiceOrOrderPaymentStatus,
  IOpenInvoice,
  ISelectedInvoiceOrOrderItem,
} from '../../redux/overview/OverviewModels';
import { Box, useTheme } from '@mui/material';
import InvoiceDetails from './Invoices/InvoiceDetails';
import { ColorPartial } from '@mui/material/styles/createPalette';

interface IInvoiceProps {
  advertiser: IAdvertiserOrAgency;
  selectedInvoices: ISelectedInvoiceOrOrderItem[];
  handleSelection: (advertiser: IAdvertiserOrAgency, invoice: IOpenInvoice) => void;
}

const Invoice = ({ advertiser, selectedInvoices, handleSelection }: IInvoiceProps) => {
  const theme = useTheme();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const items = advertiser.openInvoices || [];

  const selectedInvoice = selectedInvoices.find((selectedInvoice) => {
    return selectedInvoice?.advertiserOrAgencyId === advertiser?.advertiserOrAgencyId;
  });

  return (
    <Box sx={{ maxHeight: '400px', overflowY: 'auto', p: 5 }}>
      {items.map((invoice: IOpenInvoice, index: number) => {
        const isSelected = (selectedInvoice?.items || []).find(
          (item) => item?.number === invoice?.invoiceNumber,
        );
        return (
          <Box
            id={`item-${invoice.invoiceNumber}`}
            key={index}
            py={4}
            borderTop={`${theme.spacing(0.5)} dashed ${grey[200]}`}
          >
            <InvoiceDetails
              selectable
              isSelected={!!isSelected}
              onSelect={(invoice, checked) => handleSelection(advertiser, invoice)}
              paymentStatus={InvoiceOrOrderPaymentStatus.UnPaid}
              invoice={invoice}
            />
          </Box>
        );
      })}
    </Box>
  );
};
export default Invoice;
