export const appConstants = {
  callbackURL: process.env.REACT_APP_CALLBACK_URL || '',
  apiBaseURL: process.env.REACT_APP_API_BASE_URL || '',
  paymentUIURL: process.env.REACT_APP_PAYMENT_UI_URL || '',
  outageAlertMsg: process.env.REACT_APP_OUTAGE_MESSAGE || '',
  gciLoginURL: process.env.REACT_APP_GCI_LOGIN_URL || '',
};

export const ssoConfig = {
  ssoURL: `${process.env.REACT_APP_SSO_URL}`,
  ssoTokenURL: `${process.env.REACT_APP_SSO_TOKEN_URL}`,
  clientId: process.env.REACT_APP_SSO_CLIENT_ID || '',
  scope: process.env.REACT_APP_SSO_SCOPE || '',
};

export const authApiEndPoints = {
  loginURL: `${ssoConfig.ssoURL}/login?clientId=${ssoConfig.clientId}&callbackUrl=${appConstants.callbackURL}`,
  createAccountURL: `${ssoConfig.ssoURL}/signup?clientId=${ssoConfig.clientId}&callbackUrl=${appConstants.callbackURL}`,
  forgotPasswordURL: `${ssoConfig.ssoURL}/forgot-password?clientId=${ssoConfig.clientId}&callbackUrl=${appConstants.callbackURL}`,
  gciLoginURL: appConstants.gciLoginURL,
  tokenURL: ssoConfig.ssoTokenURL,
  userDetails: `${appConstants.apiBaseURL}/api/User/GetUserInformation`,
  signupPendingUser: `${appConstants.apiBaseURL}/api/User/SignupPendingUser`,
  signupNewUser: `${appConstants.apiBaseURL}/api/User/SignupNewUser`,
};

export const paymentApiEndPoints = {
  removeStoredPayment: `${appConstants.apiBaseURL}/api/Payment/RemoveStoredPayment`,
  setDefaultPayment: `${appConstants.apiBaseURL}/api/Payment/SetDefaultPayment`,
  storedPayments: `${appConstants.apiBaseURL}/api/Payment/GetStoredPayments`,
  upcomingPayments: `${appConstants.apiBaseURL}/api/Payment/GetUpcomingPayments`,
  upcomingPaymentsCount: `${appConstants.apiBaseURL}/api/Payment/GetUpcomingPaymentsCount`,
  cancelScheduledPayment: `${appConstants.apiBaseURL}/api/Payment/CancelScheduledPayment`,
  paymentHistory: `${appConstants.apiBaseURL}/api/Payment/GetPaymentHistory`,
  paymentHistoryDetailsURL: `${appConstants.apiBaseURL}/api/Payment/GetPaymentHistoryDetail`,
};

export const autoPayApiEndPoints = {
  addBankAccountPayment: `${appConstants.apiBaseURL}/api/Card/AddACH`,
  addCreditCardPayment: `${appConstants.apiBaseURL}/api/Card/AddCreditCard`,
  cancelRecurringPayment: `${appConstants.apiBaseURL}/api/AutoPay/CancelRecurringPayment`,
  enableAutoPay: `${appConstants.apiBaseURL}/api/AutoPay/EnableAutoPay`,
};
export const accountApiEndPoints = {
  getUserAccountInformation: `${appConstants.apiBaseURL}/api/Accounts/GetUserAccountInformation`,
  getAccountAllUsers: `${appConstants.apiBaseURL}/api/Accounts/GetAllUsers`,
  transferAccountOwnership: `${appConstants.apiBaseURL}/api/Accounts/TransferAccountOwnership`,
  removeUser: `${appConstants.apiBaseURL}/api/Accounts/RemoveUser`,
  getRoles: `${appConstants.apiBaseURL}/api/User/GetUserRoles`,
  changeRole: `${appConstants.apiBaseURL}/api/Accounts/ChangeUserRole`,
  getAdvertisers: `${appConstants.apiBaseURL}/api/User/GetAdvertisersOfUser`,
  inviteUser: `${appConstants.apiBaseURL}/api/Accounts/InviteUser`,
  manageAccessAccount: `${appConstants.apiBaseURL}/api/Accounts/ManageAccountAccess`,
};
export const invoiceAndOrderEndPoints = {
  getAgencyInvoices: `${appConstants.apiBaseURL}/api/CustomerBilling/GetAgencyOpenInvoices`,
  getAgencyOrders: `${appConstants.apiBaseURL}/api/CustomerBilling/GetAgencyOpenOrders`,
  fetchAdvertiserInvoicesURL: `${appConstants.apiBaseURL}/api/CustomerBilling/GetAdvertiserOpenInvoices`,
  fetchAdvertiserOrdersURL: `${appConstants.apiBaseURL}/api/CustomerBilling/GetAdvertiserOpenOrders`,
  fetchManagedInvoicesURL: `${appConstants.apiBaseURL}/api/CustomerBilling/GetAdvertiserManagedInvoices`,
  fetchManagedOrdersURL: `${appConstants.apiBaseURL}/api/CustomerBilling/GetAdvertiserManagedOrders`,
  downloadUnPaidInvoiceURL: `${appConstants.apiBaseURL}/api/InvoiceDownload/DownloadUnpaidInvoice`,
  downloadPaidInvoiceURL: `${appConstants.apiBaseURL}/api/InvoiceDownload/DownloadPaidInvoice`,
  getInvoiceAndOrdersList: `${appConstants.apiBaseURL}/api/Accounts/GetAccountSharingAdvertisers`,
  changeAdvertiserAccessURL: `${appConstants.apiBaseURL}/api/Accounts/ChangeAdvertiserAccess`,
  makeAgencyInvoicesPaymentURL: `${appConstants.apiBaseURL}/api/Payment/MakeAgencyOpenInvoicesPayment`,
  makeAgencyOrdersPaymentURL: `${appConstants.apiBaseURL}/api/Payment/MakeAgencyOpenOrdersPayment`,
  makeAdvertiserInvoicesPaymentURL:`${appConstants.apiBaseURL}/api/Payment/MakeAdvertiserOpenInvoicesPayment`,
  makeAdvertiserOrdersPaymentURL:`${appConstants.apiBaseURL}/api/Payment/MakeAdvertiserOpenOrderPayment`
};
export const transactionApiEndPoints = {
  getTransactionHistoryURL: `${appConstants.apiBaseURL}/api/TransactionHistory/GetTransactions`,
};
export const helpApiEndPoints = {
  getFaqsURL: `${appConstants.apiBaseURL}/api/FaqContactInformation/GetFaq`,
  getContactInfoURL: `${appConstants.apiBaseURL}/api/FaqContactInformation/GetContactInformation`,
};
