import axios from 'axios';
import { constants, getAuthItem } from '../utils/authUtils';

axios.interceptors.request.use(function (config: any) {
  return new Promise((resolve) => {
    // Should not set token in headers if its SSO request
    if (!config?.url?.includes('oauth2/token')) {
      const token = getAuthItem(constants.ACCESS_TOKEN);
      const idToken = getAuthItem(constants.ID_TOKEN);
      const sessionToken = getAuthItem(constants.SESSION_TOKEN) || '';
      config.headers.Authorization = 'Bearer ' + token;
      config.headers['X-Session-Token'] = sessionToken;
      config.headers['Id-Token'] = idToken;
    }
    resolve(config);
  });
});
export const handleError = (err: any) => {
  return new Promise((resolve, reject) => {
    if (err.response) {
      // client received an error response (5xx, 4xx)
      return reject(err?.response?.data);
    }
    if (err.request) {
      // client never received a response, or request never left
      return reject(err.request);
    }
    // anything else
    return reject(err);
  });
};

axios.interceptors.response.use(
  (response) => response,
  (error) => handleError(error),
);

// HTTP Methods
export default {
  getData: (url: string, params: any = null, headers = {}) =>
    axios({
      method: 'GET',
      url,
      params,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
    }).then((response) => response),
  postData: (url: string, params: any = null, data: any = {}, headers = {}) =>
    axios({
      method: 'POST',
      url,
      data,
      params,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
    }).then((response) => response),
  putData: (url: string, params: any = null, data: any = {}, headers = {}) =>
    axios({
      method: 'PUT',
      url,
      data,
      params,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
    }).then((response) => response),
  deleteData: (url: string, params: any = null, data: any = {}, headers = {}) =>
    axios({
      method: 'DELETE',
      url,
      data,
      params,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
    }).then((response) => response),
};
