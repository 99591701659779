import React from 'react';
import {
  IAdvertiserOrAgency,
  IOpenOrder,
  ISelectedInvoiceOrOrderItem,
} from '../../redux/overview/OverviewModels';
import { Box, useTheme } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';
import OrderDetails from './Invoices/OrderDetails';

interface IOrderProps {
  advertiser: IAdvertiserOrAgency;
  selectedItems: ISelectedInvoiceOrOrderItem[];
  handleSelection: (advertiser: IAdvertiserOrAgency, invoice: IOpenOrder) => void;
}

const Order = ({ advertiser, selectedItems, handleSelection }: IOrderProps) => {
  const theme = useTheme();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const items = advertiser.openOrders || [];
  return (
    <Box sx={{ maxHeight: '400px', overflowY: 'auto', p: 5 }}>
      {(items || []).map((order: IOpenOrder, index: number) => {
        const selectedOrder = selectedItems.find((selectedOrder) => {
          return selectedOrder?.advertiserOrAgencyId === advertiser?.advertiserOrAgencyId;
        });
        const isSelected = (selectedOrder?.items || []).find(
          (item) => item?.number + item?.dueDate === order?.orderNumber + order?.dueDate,
        );
        return (
          <Box key={index} py={4} borderTop={`${theme.spacing(0.5)} dashed ${grey[200]}`}>
            <OrderDetails
              selectable
              isSelected={!!isSelected}
              onSelect={(order, checked) => handleSelection(advertiser, order)}
              order={order}
            />
          </Box>
        );
      })}
    </Box>
  );
};
export default Order;
