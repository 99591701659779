// Packages
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import spectrumTheme from './styles/spectrum-material-theme';
import Routes from './routes/Routes';
import Layout from './components/Layout/Layout';
import { store } from './redux/Store';
import GetNewAccessToken from './pages/Auth/GetNewAccessToken';

// Styles
import './App.css';
import LogoutUserOnInactivity from './pages/Auth/LogoutUserOnInactivity';
import OutageAlert from './components/OutageAlert/OutageAlert';
import { appConstants } from './constants/apiConstants';

const App = () => {
  const outageAlertMessage = appConstants.outageAlertMsg;
  return (
    <BrowserRouter>
      <ReduxProvider store={store}>
        <ThemeProvider theme={spectrumTheme}>
          <Layout>
            <LogoutUserOnInactivity />
            <GetNewAccessToken />
            {outageAlertMessage && <OutageAlert message={outageAlertMessage} />}
            <Routes />
          </Layout>
        </ThemeProvider>
      </ReduxProvider>
    </BrowserRouter>
  );
};

export default App;
